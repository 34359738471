<div class="about-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Manufacturing</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Manufacturing</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="service-style-four pt-100 pb-70"
    style="background-color:#252628; padding-top: 25px; padding-bottom: 300px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="service-details-area">
                    <h1 style="font-weight: bold; color: aliceblue;">Manufacturing</h1>
                    <p style="text-align: justify;color: aliceblue;">Nandina's advanced manufacturing facilities boast
                        highly skilled and efficient personnel dedicated to crafting premium-quality materials at double
                        the speed, thanks to our state-of-the-art, fully equipped, modern, semi-automated machinery.
                    </p>
                    <p style="text-align: justify;color: aliceblue;">Our stringent quality assurance protocols, overseen
                        by dedicated departments, establish precise process and product performance parameters for our
                        machinery. This ensures meticulous process control and upholds the highest standards of product
                        quality.
                    </p>
                    <p style="text-align: justify;color: aliceblue;">Adhering to industry benchmarks, our fabrication
                        processes meet the rigorous standards set forth by MBMA/IS for Pre-Engineered Building (PEB)
                        members and AWS D-1.1 for welding, ensuring the reliability and durability of every product that
                        leaves our facilities.
                        At Nandina, our manufacturing facilities are the cornerstone of our commitment to excellence,
                        delivering unparalleled quality and efficiency in every aspect of our operations.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="pricing-section  pb-10" style="position:relative;bottom: 225px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="pricing-feature">
                        <img style="margin-bottom:25px ;" src="../../../../assets/img/shapes/unit-1.png" alt="">
                        <div class="title-text text-center">
                            <h1 style="font-weight: bold;">
                                2007<br>
                                Year
                            </h1>
                            <br>
                            <div class="title-text text-center">
                                <h2 style="font-weight: bold;">
                                    15,000<br>
                                    <span style="font-size: 20px;">Capacity (mt) </span>
                                </h2>
                                <br>
                            </div>
                            <div>
                                <h2 style="font-weight: bold;">
                                    Core Activity<br>
                                    <span style="font-size: 15px;">
                                        Built up, Fabrication & Structural steel </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="pricing-feature">
                        <img style="margin-bottom:25px ;" src="../../../../assets/img/shapes/unit-2.png" alt="">
                        <div class="title-text text-center">
                            <h1 style="font-weight: bold;">
                                2017<br>
                                Year
                            </h1>
                            <br>
                            <div class="title-text text-center">
                                <h2 style="font-weight: bold;">
                                    15,000<br>
                                    <span style="font-size: 20px;">Capacity (mt) </span>
                                </h2>
                                <br>
                            </div>
                            <div>
                                <h2 style="font-weight: bold;">
                                    Core Activity<br>
                                    <span style="font-size: 15px;">
                                        Built up, Fabrication & Structural steel </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="pricing-feature">
                        <img style="margin-bottom:25px ;" src="../../../../assets/img/shapes/unit-3.png" alt="">
                        <div class="title-text text-center">
                            <h1 style="font-weight: bold;">
                                2024<br>
                                Year
                            </h1>
                            <br>
                            <div class="title-text text-center">
                                <h2 style="font-weight: bold;">
                                    15,000<br>
                                    <span style="font-size: 20px;">Capacity (mt) </span>
                                </h2>
                                <br>
                            </div>
                            <div>
                                <h2 style="font-weight: bold;">
                                    Core Activity<br>
                                    <span style="font-size: 15px;">
                                        Built up, Fabrication & Structural steel </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <!-- <section class="portfolio-section " style="margin-top: -195px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/1.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Single Source Responsibility</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i
                                    class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/2.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Faster Installation</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i
                                    class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/3.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Overall Economical</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i
                                    class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/4.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Practically Maintenance Free
                            </h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i
                                    class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/5.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Virtual Assistant Service</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i
                                    class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/6.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Flexibility In Expansion</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i
                                    class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="portfolio-section  pb-70"  style="margin-top: -210px;">
    <div class="container">
      <div class="row">
        <div class="col-md-4" *ngFor="let img of images">
          <div class="gallery">
            <img [src]="img.src" (click)="openPopup(img.src)" class="thumbnail" alt="Image Thumbnail">
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <div *ngIf="selectedImage" class="popup" (click)="closePopup()">
    <img [src]="selectedImage" class="popup-image" alt="Popup Image">
  </div>
  
  






