import { Component, OnInit } from '@angular/core';
// import { ModalDismissReasons, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  images = [
    { src: 'assets/img/nandina/16.jpg' },
    { src: 'assets/img/nandina/3.jpg' },
    { src: 'assets/img/nandina/15.jpg'},
    { src: 'assets/img/nandina/7.jpg' },
    { src: 'assets/img/nandina/13.jpg'},
    { src: 'assets/img/nandina/9.jpg' },
    { src: 'assets/img/nandina/18.jpg' },
    { src: 'assets/img/nandina/6.jpg' },
    { src: 'assets/img/nandina/10.jpg' }
    // Add more images as needed
  ];

  selectedImage: string | null = null;

  openPopup(imageSrc: string) {
    this.selectedImage = imageSrc;
  }
  closePopup() {
    this.selectedImage = null;
  }
  constructor() { }
  ngOnInit(): void {

  }
}
