<app-navbar-two></app-navbar-two>

<div class="about-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Vision & Mission
                </h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Vision & Mission
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="service-section service-style-two">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 service-img"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="service-text">
                    <h2>We Understand the User Need and Those Servicesfor Them</h2>
                    <ul>
                        <li><i class="flaticon-checkmark"></i>Building Networking</li>
                        <li><i class="flaticon-checkmark"></i>Software Development</li>
                        <li><i class="flaticon-checkmark"></i>Business Strategy</li>
                        <li><i class="flaticon-checkmark"></i>Business Management</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="progress-section progress-style-two">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 p-0">
                <div class="progress-text">
                    <h2>We Always Try to be the Best Support to Our Clients as Possible</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida</p>

                    <div class="progress-inner">
                        <p>Software Development</p>
                        <span>70%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                    <div class="progress-inner">
                        <p>Digital Marketing</p>
                        <span>85%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                    <div class="progress-inner">
                        <p>Business Management</p>
                        <span>95%</span>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 progress-image"></div>
        </div>
    </div>
</div>


<section class="blog-section blog-details-area pt-100 pb-70">
    <div class="container">               
        <div class="row">
        

    
        </div>
    </div>
</section>