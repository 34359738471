


<div class="about-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Gallery</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Gallery</li>
                </ul>
            </div>
        </div>
    </div>
</div>



 <!-- <section class="portfolio-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/1.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Single Source Responsibility</h3>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/2.jpg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Faster Installation</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/3.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Overall Economical</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/4.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Practically Maintenance Free
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/5.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Virtual Assistant Service</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/6.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Flexibility In Expansion</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>  -->




<!-- /////new code starts images -->
<!-- <section class="portfolio-section pt-100 pb-70">
    <div class="container">
<div class="row">
    <div class="col-md-4">
        <div class="portfolio-item" >
            <div class="portfolio-img">
                <img src="assets/img/nandina/16.jpg"  style="height:200px" alt="portfolio image">
                <div class="portfolio-text">
                    <h3>Single Source Responsibility</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="portfolio-item">
            <div class="portfolio-img">
                <img src="assets/img/nandina/3.jpg" style="height:200px" alt="portfolio image">
                <div class="portfolio-text">
                    <h3>Single Source Responsibility</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="portfolio-item">
            <div class="portfolio-img">
                <img src="assets/img/nandina/15.jpg" style="height:200px;width:500px" alt="portfolio image">
                <div class="portfolio-text">
                    <h3>Single Source Responsibility</h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <div class="portfolio-item">
            <div class="portfolio-img">
                <img src="assets/img/nandina/7.jpg" style="height:200px" alt="portfolio image">
                <div class="portfolio-text">
                    <h3>Single Source Responsibility</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="portfolio-item">
            <div class="portfolio-img">
                <img src="assets/img/nandina/13.jpg" style="height:200px" alt="portfolio image">
                <div class="portfolio-text">
                    <h3>Single Source Responsibility</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="portfolio-item">
            <div class="portfolio-img">
                <img src="assets/img/nandina/9.jpg" style="height:200px" alt="portfolio image">
                <div class="portfolio-text">
                    <h3>Single Source Responsibility</h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <div class="portfolio-item">
            <div class="portfolio-img">
                <img src="assets/img/nandina/18.jpg" style="height:200px" alt="portfolio image">
                <div class="portfolio-text">
                    <h3>Single Source Responsibility</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="portfolio-item">
            <div class="portfolio-img">
                <img src="assets/img/nandina/19.jpg" style="height:200px" alt="portfolio image">
                <div class="portfolio-text">
                    <h3>Single Source Responsibility</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="portfolio-item">
            <div class="portfolio-img">
                <img src="assets/img/nandina/10.jpg" style="height:200px" alt="portfolio image">
                <div class="portfolio-text">
                    <h3>Single Source Responsibility</h3>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</section> -->
<!-- /////new code ends images -->


<!-- /////new popup code starts -->
<section class="portfolio-section pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-md-4" *ngFor="let img of images">
          <div class="gallery">
            <img [src]="img.src" (click)="openPopup(img.src)"  class="thumbnail" alt="Image Thumbnail">
          </div>
        </div>
      </div>
    </div>
  </section>
  <div *ngIf="selectedImage" class="popup" (click)="closePopup()">
    <img [src]="selectedImage" class="popup-image" alt="Popup Image">
  </div>
<!-- /////new popup code ends -->