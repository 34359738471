import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class ContactService {
  PermissionApi = environment.texturespaceURL;
  constructor(private http:HttpClient) {
    
   }
  contactpost(data){
    console.log(data,14);
    return this.http.post(this.PermissionApi + 'submitform',data).pipe(map(res => {
      return res;
    }, (error: any) => {
      return error;
    }));
  }

}
