import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../contact.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  submitted: boolean = false;
  showSpinner: boolean = false;
  constructor(private serv: ContactService, private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      name: ['',],
      email: ['',],
      phone: ['',],
      requirement: ['',]
    })
  }

  ngOnInit(): void {
  }
  // onSubmit() {
  //   // this.showSpinner = true;
  //   this.showSpinner = true;
  //   this.serv.contactpost(this.contactForm.value).subscribe((res: any) => {
  //     this.showSpinner = false;
  //     if (res.status == 200) {
  //       this.contactForm.reset();
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Successfully Added',
  //         text: '',
  //         timer: 1500
  //       })
  //     }
  //   }, 
  //   er => {
  //     console.log('error');
  //   })

  // }


  onSubmit() {
    // Show spinner to indicate loading
    this.showSpinner = true;
  
    // Call the backend service to submit the form data
    this.serv.contactpost(this.contactForm.value).subscribe(
      (res: any) => {
        // Hide spinner once response is received
        this.showSpinner = false;
  
        // Check the status of the response
        if (res.status === 200) {
          // Reset the form if the submission is successful
          this.contactForm.reset();
  
          // Show success message
          Swal.fire({
            icon: 'success',
            title: 'Successfully Added',
            text: ' successfully added.',
            timer: 1500
          });
        } else {
          // Handle unexpected statuses if needed
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong, please try again.',
            timer: 1500
          });
        }
      },
      (error) => {
        // Hide spinner if an error occurs
        this.showSpinner = false;
        // Log the error to the console for debugging
        console.error('Error:', error);
        // Show error message
        Swal.fire({
          icon: 'error',
          title: 'Submission Failed',
          text: 'There was an issue submitting your contact information. Please try again later.',
          timer: 1500
        });
      }
    );
  }
  
  get valid() {
    return this.contactForm.controls;
  }
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
}
