<div class="swiper">
    <div class="swiper-inner" [style.transform]="'translateX(' + (-currentIndex * 100) + '%)'">
      <div class="swiper-item" *ngFor="let item of carouselItems">
        <img [src]="item.imageUrl" alt="Carousel Image">
   
      </div>
    </div>
    <button (click)="prev()" class="prev">&#10094;</button>
    <button (click)="next()" class="next">&#10095;</button>
  </div>
  