<app-swiper></app-swiper>
<div class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1 style="font-size: 30px;"> Your Business Our Responsibility : Nandina Build Tech</h1>
                    <h2>Need Content</h2>
                    <!-- <p style="text-align: justify;">Nandina's dedication to delivering a comprehensive steel solution,
                        characterized by cost-effective, top-quality materials, swift delivery, and exceptional customer
                        service, sets an unmatched standard within the industry.
                    </p> -->
                    <div class="theme-button">
                        <a routerLink="/" class="default-btn">Get Started</a>
                        <a href="assets/video_nandina2.mp4" class="video-btn popup-vimeo"><i class="bx bx-play"></i> Watch
                            video</a>
                    </div>
                </div>
                <div class="banner-image">
                    <img src="assets/img/about/6.png" alt="banner image">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="video-section pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="video-text">
                    <a href="assets\NANDINA  BUILD TECH (2).mp4" class="popup-vimeo"><i class='bx bx-play'></i></a>
                    <p>Watch our video and know more about us</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <!-- <h2>My Theory is Customer Service is First</h2> -->
                      <h2>Benefits Of Nandina</h2>
                      <h2>Need Content</h2>
                    <!-- <p>At Nandina Build Tech, quality is our hallmark. We adhere to stringent quality control measures
                        to ensure that every project meets the highest standards of excellence. Our commitment to
                        quality assurance is reflected in every aspect of our work, from material selection to project
                        execution.
                    </p> -->
                    <div class="signature-box">
                        <!-- <img src="assets/img/signature.png" alt="signature"> -->
                        <p>T.Krishna Rao</p>
                        <span>Founder and CEO</span>
                    </div>
                </div>
            </div>
            <div class="theory-img">
                <img src="assets/img/main.jpg" alt="theory image">
            </div>
        </div>
    </div>
</div>

<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about/6.png" alt="about image">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <!-- <span>About Us</span> -->
                        <h2>The Modern Way to Make Succesful Business</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner icon-one"></i>
                                <p>Award Winner</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service icon-two"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                    </div>
                    <p style="text-align: justify;">At Nandina Build Tech, our dedication to providing a comprehensive
                        "One Stop Construction Solution" with top-quality materials, efficient service, and unmatched
                        expertise sets us apart in the industry.
                    </p>
                    <div class="theme-button">
                        <a routerLink="/about" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features-section pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Oue Feature</span>
            <h2>Our Powerful Features</h2>
            <p>With a steadfast focus on quality, efficiency, and sustainability, we have earned a reputation as a
                trusted leader in the field of construction.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-one">
                    <i class="flaticon-technical-support"></i>
                    <h3>Client-Centric Approach
                    </h3>
                    <p style="text-align: justify;">Customer satisfaction is at the heart of everything we do. We pride
                        ourselves on building long-lasting relationships with our clients based on trust, integrity, and
                        exceptional service.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-two">
                    <i class="flaticon-megaphone"></i>
                    <h3>Integrity</h3>
                    <p style="text-align: justify;"> Integrity is the foundation of our business. We uphold the highest
                        ethical standards in all our interactions, fostering trust and transparency with our clients,
                        partners, and stakeholders.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-three">
                    <i class="flaticon-optimization"></i>
                    <h3>Innovation and Sustainability</h3>
                    <p style="text-align: justify;">Nandina Build Tech is committed to innovation and sustainability in
                        every project we undertake. We continuously explore new technologies and methodologies</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-four">
                    <i class="flaticon-first-place"></i>
                    <h3>Our Vision
                    </h3>
                    <p style="text-align: justify;">Nandina Build Tech envisions a future where construction is
                        synonymous with excellence, sustainability, and innovation</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-five">
                    <i class="flaticon-alarm-clock"></i>
                    <h3>Our Core Values
                    </h3>
                    <p style="text-align: justify;"> We strive for excellence in everything we do, from project planning
                        to execution, to ensure the highest quality outcomes for our clients</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six">
                    <i class="flaticon-newsletter"></i>
                    <h3>Get in Touch
                    </h3>
                    <p style="text-align: justify;">Contact us to learn more about our services, discuss your project
                        requirements, or request a quote .</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ////new code changes starts -->
<!-- <div class="service-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 service-img"></div>
            <div class="col-md-7 offset-md-5 p-0">
                <div class="service-text">
                    <h2>We Understand the User Need and Those Services for Them</h2>
                    <ul>
                        <li><i class="flaticon-checkmark"></i> Pre-Engineered Buildings</li>
                        <li><i class="flaticon-checkmark"></i> Structural Steel</li>
                        <li><i class="flaticon-checkmark"></i> Steel Processing Center</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- ////new code changes ends-->
 <!-- <div class="testimonial-section" style="margin-top: 100px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 offset-lg-1 pt-100 pb-100">
                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="slider-items">
                        <img src="assets/img/testimonial/1.jpg" alt="testimonial image">
                        <h3>Adision Smith</h3>
                        <span> Cxxxxx </span>
                        <p>"Nandina Build Tech exceeded our expectations with their professionalism, attention to
                            detail, and commitment to delivering on time and within budget. Their team was a pleasure to
                            work with, and we couldn't be happier with the results."
                        </p>
                    </div>

                    <div class="slider-items">
                        <img src="assets/img/testimonial/2.jpg" alt="testimonial image">
                        <h3>John Smith</h3>
                        <span>Project xxxxx</span>
                        <p>"Working with Nandina Build Tech was a seamless experience from start to finish. Their
                            expertise and dedication to quality were evident throughout the project, and they
                            consistently went above and beyond to ensure our satisfaction. I highly recommend them for
                            any construction project."

                        </p>
                    </div>

                    <div class="slider-items">
                        <img src="assets/img/testimonial/3.jpg" alt="testimonial image">
                        <h3>Anderson</h3>
                        <span>Dxxxxx</span>
                        <p>Nandina Build Tech delivered a state-of-the-art healthcare facility that exceeded our
                            expectations in every way. Their attention to detail, proactive communication, and
                            commitment to excellence were truly impressive. We look forward to partnering with them on
                            future projects.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 p-0">
                <div class="testimonial-image"></div>
            </div>
        </div>
    </div>
</div> -->

<!-- <section class="portfolio-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center" >
            <span>Our Portfolio</span>
            <h2 >Latest Works For Clients</h2>
            <p>At Nandina Build Tech, we take pride in our diverse portfolio of construction projects, each representing
                our commitment to excellence, innovation, and client satisfaction..</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/1.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Flexibility In Expansion</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/2.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Steel Building Components</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/3.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Practically Maintenance</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/4.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Faster Installation</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/5.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Virtual Assistant Service</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-4">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/portfolio/6.jpg" alt="portfolio image">
                        <div class="portfolio-text">
                            <h3>Overall Economical</h3>
                            <a routerLink="/portfolio" class="portfolio-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="theme-button text-center">
            <a routerLink="/portfolio" class="default-btn">Load More</a>
        </div>
    </div>
</section> -->



<!-- <section class="blog-section  pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Blog</span>
            <h2>Our Latest Blogs</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/12.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>21 Feb</span>
                        </div>
                    </div>
                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">The next genaration IT will change the world</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-1.png" alt="blog author">
                            <a routerLink="/">
                                <p>Suresh</p>
                            </a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/13.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>22 Feb</span>
                        </div>
                    </div>
                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">Content writing demand day by day increasing</a></h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-2.png" alt="blog author">
                            <a routerLink="/">
                                <p>John</p>
                            </a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/14.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>23 Feb</span>
                        </div>
                    </div>
                    <div class="blog-text">
                        <h3><a routerLink="/blog-details">Don't buy a tech gift until you read these rules image</a>
                        </h3>
                        <div class="post-info">
                            <img src="assets/img/blog/author-3.png" alt="blog author">
                            <a routerLink="/">
                                <p>Kumar</p>
                            </a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <div class="contact-section pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/img/11.jpg" alt="contact">
            </div>
            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us.We will get you</p>
                    </div>
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control"
                                        placeholder="Email">
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                        placeholder="Subject">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5"
                                        placeholder="Message"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div> -->