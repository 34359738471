import { Component, OnInit, OnDestroy } from '@angular/core';


interface CarouselItem {
  imageUrl: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent implements OnInit, OnDestroy {
  currentIndex: number = 0;
  carouselItems: CarouselItem[] = [
    {
      imageUrl: 'assets/img/2.png',
      title: 'Image 1',
      description: 'Description for Image 1'
    },
    {
      imageUrl: 'assets/img/3.png',
      title: 'Image 2',
      description: 'Description for Image 2'
    },
    {
      // imageUrl: 'assets/img/NANDANA BANNER.png',
      imageUrl:'assets/img/NANDANA BANNER (1).png',
      title: 'Image 3',
      description: 'Description for Image 3'
    },
    // Add more carousel items as needed
  ];
  private intervalId: any;
  ngOnInit() {
    this.startSlide();
  }
  ngOnDestroy() {
    this.stopSlide();
  }
  startSlide() {
    this.intervalId = setInterval(() => {
      this.next();
    }, 3000); // Change slide every 3 seconds (adjust as needed)
  }
  stopSlide() {
    clearInterval(this.intervalId);
  }
  prev() {
    this.currentIndex = (this.currentIndex - 1 + this.carouselItems.length) % this.carouselItems.length;
  }
  next() {
    this.currentIndex = (this.currentIndex + 1) % this.carouselItems.length;
  }
}
