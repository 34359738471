<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="header-left-text">
                    <ul>
                        <li><a href="tel:9666196652"><i class="flaticon-call"></i> 9666196652</a></li>
                        <li><a href="mailto:vnsnandina&#64;gmail.com"><i class="flaticon-envelope"></i>vnsnandina&#64;gmail.com</a></li>
                        <li><i class="flaticon-maps-and-flags"></i> 28/A Street</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="header-right text-end">
                    <div class="header-social">
                        <ul>
                            <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            <li><a  href="mailto:vnsnandina@gmail.com" target="_blank"><i class="flaticon-envelope"></i></a></li>
                         
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-2">
            <button (click)="downloadPDF()">Download</button>
            </div> -->
           
        </div>
    </div>
</header>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo-2.png" alt="logo">
        </a>
    </div>
    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo">
                </a>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">About Us <i class="flaticon-right"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Company Profile</a></li>

                                <!-- <li class="nav-item"><a routerLink="/blog-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Vision & Mission</a></li> -->

                                <!-- <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our Team</a></li> -->

                                <!-- <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Quality & Certification</a></li> -->
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/manufacturing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Manufacturing</a></li>
                        <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Products & Services <i class="flaticon-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pre-Engineered Buildings <i class="flaticon-right"></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/peb/about-peb" class="nav-link">About PEB</a></li>

                                        <li class="nav-item"><a routerLink="/peb/primary-frame-system" class="nav-link">Primary Frame System</a></li>
                                        <li class="nav-item"><a routerLink="/peb/secondary-members" class="nav-link">Secondary Members</a></li>
                                        <li class="nav-item"><a routerLink="/peb/roof-wall-sheeting" class="nav-link">Roof & Wall Sheeting</a></li>
                                        <li class="nav-item"><a routerLink="/peb/trims-flashing" class="nav-link">Trims/Flashing</a></li>
                                        <li class="nav-item"><a routerLink="/peb/peb-accessories" class="nav-link">PEB Accesories</a></li>

                                       
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/services/structural-steel" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Structural Steel
                                </a></li>
                                <li class="nav-item"><a routerLink="/services/engineering-services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Engineering Services</a></li>

                                <li class="nav-item"><a routerLink="/services/steel-building-components" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Steel Building Components
                                </a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Portfolio <i class="flaticon-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/portfolio" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio One</a></li>

                                <li class="nav-item"><a routerLink="/portfolio-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Two</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class="flaticon-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/team-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team Two</a></li>

                                <li class="nav-item"><a routerLink="/privacy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy policy</a></li>

                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Hover for action <i class="flaticon-right"></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/" class="nav-link">Level 2</a></li>

                                        <li class="nav-item"><a routerLink="/" class="nav-link">Level 2</a></li>

                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Level 2 <i class="flaticon-right"></i></a>

                                            <ul class="dropdown-menu">
                                                <li class="nav-item"><a routerLink="/" class="nav-link">3rd Level</a></li>

                                                <li class="nav-item"><a routerLink="/" class="nav-link">3rd Level</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">CSR</a></li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i class="flaticon-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog One</a></li>

                                <li class="nav-item"><a routerLink="/blog-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Two</a></li>

                                <li class="nav-item"><a routerLink="/blog-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Three</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>
                    <div class="other-option">
                        <!-- <div class="search-bar">
                            <i class="flaticon-search search-icon"></i>
                            <div class="search-form">
                                <form>
                                    <input type="text" placeholder="Search" class="search-input">
                                    <button type="submit">
                                        <i class="flaticon-search search-btn"></i>
                                    </button>
                                </form>
                            </div>
                        </div> -->

                        <!-- <div class="sidebar-toggle">
                            <button type="button" class="btn btn-demo toggle-button navbar-toggle" data-bs-toggle="modal" data-bs-target="#sidebar-right">
                                <i class="flaticon-list"></i>
                            </button>
                        </div> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>