import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-one',
  templateUrl: './navbar-one.component.html',
  styleUrls: ['./navbar-one.component.scss']
})
export class NavbarOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }
  // Method to handle the download
  downloadPDF(): void {
    // Create a link element
    const link = document.createElement('a');
    link.href = '/assets/img/sample.pdf'; // Path to your sample PDF file
   
    link.download = 'sample.pdf'; // Filename for the downloaded file

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);
    
    // Trigger a click on the link
    link.click();
    
    // Remove the link from the body
    document.body.removeChild(link);
  }
}
