<div class="newsletter-section" style="margin-top:50px">
    <div class="container">
        <div class="newsletter-area">
            <h2>Subscribe Our Newsletter</h2>
            <div class="col-lg-6 offset-lg-3 p-0">
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                    <button class="default-btn electronics-btn" type="submit">Subscribe</button>
                </form>
            </div>
            <img src="assets/img/shapes/2.png" class="subscribe-shape" alt="shape">
        </div>
    </div>
</div>

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo-2.png" alt="logo"></a>
                    </div>
                    <p>At Nandina Build Tech, our dedication to providing a comprehensive "One Stop Construction Solution" with top-quality materials, efficient service, and unmatched expertise sets us apart in the industry.</p>
                    <div class="footer-social">
                        <a href="#" target="_blank"><i class="flaticon-facebook"></i></a>
                        <!-- <a href="#" target="_blank"><i class="flaticon-twitter"></i></a> -->
                        <a href="#" target="_blank"><i class="flaticon-instagram"></i></a>
                        <a href="#" target="_blank"><i class="flaticon-linkedin"></i></a>
                        <a  href="mailto:vnsnandina@gmail.com" target="_blank"><i class="flaticon-envelope"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><a routerLink="/"><i class="flaticon-right"></i> Home</a></li>
                        <li><a routerLink="/about"><i class="flaticon-right"></i> Company Profile</a></li>
                        <li><a routerLink="/manufacturing"><i class="flaticon-right"></i> Manufacturing</a></li>
                        <li><a routerLink="/gallery"><i class="flaticon-right"></i> Gallery</a></li>
                        <li><a routerLink="/contact"><i class="flaticon-right"></i> Contact</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><a routerLink="/"><i class="flaticon-right"></i> Home</a></li>
                        <li><a routerLink="/about"><i class="flaticon-right"></i> About Us</a></li>
                        <li><a routerLink="/blog"><i class="flaticon-right"></i> Blog</a></li>
                        <li><a routerLink="/team"><i class="flaticon-right"></i> Team</a></li>
                        <li><a routerLink="/faq"><i class="flaticon-right"></i> FAQ</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-md-4 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Find Us</h3>
                    <ul>
                        <li><i class="flaticon-right"></i> 28/A Street, </li>
                        <li><a href="tel:+13325845845"><i class="flaticon-right"></i> +13xxxxxx</a></li>
                        <li><a href="tel:+13325845845"><i class="flaticon-right"></i> 91+9912599xxxxxxx</a></li>
                        <li><a href="mailto:vnsnandina&#64;gmail.com"><i class="flaticon-right"></i> vnsnandina&#64;gmail.com</a></li>
                        <!-- <li><a href="mailto:info&#64;ezlu.com"><i class="flaticon-right"></i> info&#64;ezlu.com</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 lh-1 text-start">
                    <p>© Nandina Build Tech Designed & Developed by <a href="https://amaravathisoftware.com/" target="_blank">Amaravathi Software</a></p>
                </div>
                <div class="col-md-6 lh-1 text-end">
                    <ul>
                        <li><a routerLink="/privacy">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- <div class="right-modal">
    <div class="modal fade right" id="sidebar-right" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 p-0">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/home-two"><img src="assets/img/modal/2.jpg" alt="home one"></a>
                                           <h3><a routerLink="/home-two">Home Two</a></h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/about"><img src="assets/img/modal/3.jpg" alt="home one"></a>
                                           <h3><a routerLink="/about">About</a></h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/service"><img src="assets/img/modal/4.jpg" alt="home one"></a>
                                           <h3><a routerLink="/service">Service</a></h3>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="modal-link">
                                           <a routerLink="/portfolio"><img src="assets/img/modal/5.jpg" alt="home one"></a>
                                           <h3><a routerLink="/portfolio">Portfolio</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->