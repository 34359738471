import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-three',
  templateUrl: './blog-three.component.html',
  styleUrls: ['./blog-three.component.scss']
})
export class BlogThreeComponent implements OnInit {
  images = [
    { src: 'assets/img/nandina/11.jpg' },
    { src: 'assets/img/nandina/2.jpg' },
    { src: 'assets/img/nandina/21.jpg' },
    { src: 'assets/img/nandina/22.jpg' },
    { src: 'assets/img/nandina/8.jpg' },
    { src: 'assets/img/nandina/1.jpg' }
    // Add more images as needed
  ];

  selectedImage: string | null = null;

  openPopup(imageSrc: string) {
    this.selectedImage = imageSrc;
  }

  closePopup() {
    this.selectedImage = null;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
