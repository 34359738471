<div class="about-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>About Us</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>About Us</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="about-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <!-- <img src="assets/img/about/5.png" alt="about image"> -->
                     <h2>Need  Director Image</h2>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <span>Company Profile</span>
                        <h2>About Nandina Build Tech</h2>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner icon-one"></i>
                                <p>Award Winner</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service icon-two"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                    </div>
                    <!-- <p style="text-align: justify;">Nandina has been at the forefront of delivering superior quality and cost-effective solutions in the realm of Pre-Engineered Steel Buildings since its establishment in 2007. Today, Nandina stands as a premier "One-Stop Steel Solution" provider, boasting a production capacity of 60,000 metric tons per annum. Our diverse product portfolio encompasses Pre-Engineered Buildings, Structural Steel, Steel Processing centers, and Steel Building Components.
                      We attribute our success to the unparalleled efficiency of our team in designing, planning, and manufacturing a wide array of steel solutions. With a track record of over 1000 completed steel buildings across India and Africa, Nandina has established itself as a trusted name in the industry. -->
                      <h2>Need Content</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="theory-section theory-style-two">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>My Theory is Customer Service is First</h2>
                    <!-- <p>At Nandina, our commitment to delivering a comprehensive "One-Stop Steel Solution" remains unwavering. We take pride in offering cost-effective, high-quality materials with swift turnaround times and exceptional sales service, setting new benchmarks in the industry.</p>  -->
                    <h2>Need Content</h2>
                    <div class="signature-box">
                        <img src="assets/img/signature.png" alt="signature">
                        <p>T.Krishna Rao</p>
                        <p>Ph No:-7659088811</p>
                        <span>Founder and CEO</span>
                    </div>
                </div>
            </div>
            <div class="theory-img">
                <img src="assets/img/SS.jpg" alt="theory image">
            </div>
        </div>
    </div>
</div>

<div class="video-style-two video-style-three">
    <div class="container-fluid">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="video-text text-center">
                    <a href="assets\NANDINA  BUILD TECH (2).mp4" class="popup-vimeo"><i
                            class="bx bx-play"></i></a>
                    <p>Watch our video</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contact-section pt-10 pb-100">
   
</div>
